import React, { Component } from 'react';

import FadeIn from '../_core/components/FadeIn';
import ScrollTo from '../_core/components/ScrollTo';
import SiteModifier from '../_core/components/SiteModifier';
import Icon from '../_core/components/Icon';
import Content from '../_core/components/Content';
import Link from '../_core/components/Link';
import Media from '../_core/components/Media';

import Template from '../_core/controllers/Template';

import Data from '../_core/models/Data';
import Entry from '../_core/models/Entry';

import settings from '../_settings';

import {Breakpoints} from '../_core/utils';
import {Helmet} from "react-helmet";

// Project
import Project from '../components/';
import ArticleTemplate from './article';

class Layout extends Component {

	ref = {};

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	setRef = (label,el) => {
		this.ref[label] = el;
	}

	
	/*

		RENDER

	*/

	render() {

		

		return (

			<FadeIn duration={1.5}>
				
				<div data-layout className="Site-page">

					{(()=>{

              			if(window.location.pathname == '/'){
              				return (<SiteModifier modifier={'home'} key="home" auto />)
              			}else{
              				return (<SiteModifier demodifier={'home'} key="nohome" auto />)
              			}

              		})()}

					{/* Auto Scroll Reset */}
					<ScrollTo useHistory={true} ignore={({pathname},history) => { return window.location.hash }} />

					{/* Intro */}
					<SiteModifier modifier="open" auto delay={0} />

					{/* Header */}
					<header className="Site-head">
						<Project.Header />
					</header>

					{/* Menu */}
					<nav className="Site-menu">
					
						<Project.Menu />
						
					</nav>
					<SiteModifier demodifier="menu">
            			<div className="Site-menu-close" />
         			</SiteModifier>

					{/* Template */}
					<main className="Site-template">
						
						
						<Template />

						<div className="Site-book">
							<div className="Site-book-sticky"><div className="Site-book-button">
							<Data>
								{({HOME,CTA})=>{

									if(CTA){
										return (<Link to={CTA.link} className="Link Link--booking"><span>{CTA.text}</span></Link>)
									}else{
										return null
									}
									
								}}
							</Data>
							<Data require={['HOME']}>
								{({HOME})=>{

									if(HOME.hasOwnProperty('booking_button_override') && HOME.booking_button_override){
										return (<Link to={HOME.booking_button_override} className="Link Link--booking"><span>{HOME.booking_button_text}</span></Link>)
									}else{
										return <Link to={'#/book/'} className="Link Link--booking"><span>{HOME.booking_button_text}</span></Link>
									}
									
								}}
							</Data>
							
							</div></div>
						</div>
					</main>

					{/* Footer */}
					<footer className="Site-foot">
						<Project.Footer />
					</footer>

					{/* Site Reader Panel */}
					<Data require={['HOME','entries']}>
	                  {
	                    ({READING,READ_ARTICLE,CAREER_ID})=>{

	                    	
	                        return (


	                        
	                          <div className="Site-read">

	                          		{(()=>{

	                          			if(READING){
	                          				return (<SiteModifier modifier={'reading'} key="reading" auto />)
	                          			}else{
	                          				return (<SiteModifier demodifier={'reading'} key="noreading" auto />)
	                          			}

	                          		})()}



	                             <div className="Site-read-article">
	                              
	                                <Link hashLink hashKey={((CAREER_ID) ? 'vacancy' : 'read')} hashValue={false} hashRemoveEmpty={true} className="Site-read-close">
	                                  <Icon glyph type={'cross'} />
	                                </Link>
	                         
	                               
	                        	
	                        		<div className="Site-read-content">
	                              		{(()=>{
	                              			if(READ_ARTICLE){
	                              				return <article><ArticleTemplate entry={READ_ARTICLE} /></article>
	                              			}
	                              		})()}
	                              		{(()=>{
	                              			if(CAREER_ID){
	                              				return <Data require={['careers']}>
	                              				{({careers}) => {

	                              					let found = careers.find((c)=>c.id == CAREER_ID);
	                              					let vacancy = {};
	                              					if(found){
	                              						vacancy = found
	                              					}else{

	                              						vacancy = {

	                              							title : 'Vacancy Not found',
	                              							content :  `<p>We are sorry, we can't find that vacancy. The position may have been removed, or may no longer be available...</p>`

	                              						}

	                              					}

	                              					return <article><ArticleTemplate entry={vacancy} career={true} /></article>

	                              				}}
	                              				</Data>
	                              			}
	                              		})()}
	                              	</div>
	                        
	                            </div>

	                            <Link hashLink hashKey={((CAREER_ID) ? 'vacancy' : 'read')} hashValue={false} hashRemoveEmpty={true}>
	                              <div className="Site-read-bg" />
	                            </Link>

	                          </div>
	                 
	                      )

	                    }
	                  }
	                 </Data>
	                  {/* End Site Reader Panel */}

					{/* Site Widget */}
					<Data require={['HOME','entries']}>
	                  {
	                    ({HOME,BOOKING,BOOK_COUNT,BOOKING_ID,entries})=>{

	                    	let venueIds = entries.filter((e) => ((e.type == "venues") && (e.booking_venue_id != ''))).propSort('title',1).map((v) => (v.booking_venue_id)).join(',');
									
	               
	                        return (


	                        
	                          <div className="Site-widget">

	                          		{(()=>{

	                          			if(BOOKING){
	                          				return (<SiteModifier modifier={'widget'} key="widget" auto />)
	                          			}else{
	                          				return (<SiteModifier demodifier={'widget'} key="nowidget" auto />)
	                          			}

	                          		})()}

	                             <div className="Site-widget-box">
	                              
	                                <Link to={'#back'} className="Site-widget-close">
	                                  <Icon glyph type={'cross'} />
	                                </Link>
	                         
	                               <div className="Site-widget-iframe">
	                               	{(()=>{

	                          			if(BOOKING || BOOK_COUNT > 0){
	                          				return (<iframe src={`//www.sevenrooms.com/explore/${BOOKING_ID}/reservations/create/search?tracking=jkswebsite&venues=${venueIds}`} />)
	                          			}else{
	                          				return null;
	                          			}

	                          		})()}
	                               	
	                               </div>
	                        	
	                        	<div className="Site-widget-content">
	                        		{(()=>{

	                        			if(HOME.global_widget_image){
	                        				return <div className="Site-widget-image"><Media id={HOME.global_widget_image} fluid={false} /></div>
	                        			}else{
	                        				return null;
	                        			}

	                        		})()}
	                              <Content modifier="widget" html={HOME.global_widget_text} />
	                              </div>
	                        
	                            </div>

	                            <Link to={`#back`}>
	                              <div className="Site-widget-bg" />
	                            </Link>

	                          </div>
	                 
	                      )

	                    }
	                  }
	                 </Data>
	                  {/* End site message */}

					{/* Site message */}
					<Data require={['HOME']}>
	                  {
	                    ({HOME})=>{

	                      if(HOME.showPopup){
	                        return (

	                        	<SiteModifier modifier={'noMessage,open'}>
	                          <div className="Site-message">

	                             <div className="Site-message-content">
	                              
	                                <a className="Site-message-close">
	                                  <Icon glyph type={'cross'} />
	                                </a>
	                         

	                        
	                              <Content html={HOME.popupMessage} />
	                        
	                            </div>

	                            <SiteModifier modifier={'noMessage'}>
	                              <div className="Site-message-bg" />
	                            </SiteModifier>

	                          </div>
	                           </SiteModifier>
	                        )
	                      }

	                    }
	                  }
	                 </Data>
	                  {/* End site message */}

	                  {/* Meta */}
						<Data require={['ENTRY']}>
				            {({ENTRY,READING,READ_ARTICLE})=>{


				              let META = ENTRY;
				              if(READING && READ_ARTICLE) META = READ_ARTICLE;
				              
				              if(META){
				                return (

				                  <Helmet>
				                    <meta property="og:description" content={META.metaDescription}/>
				                    <meta name="description" content={META.metaDescription} />
				                    <meta name="twitter:description" content={META.metaDescription} />
				                    <meta name="keywords" content={META.metaKeywords} />
				                    <title>{((META.metaTitle) ? META.metaTitle.decodeHTML() : META.title.decodeHTML())} • JKS Restaurants</title>
				                    {(()=>{

				                      if(META.hasOwnProperty('schema') && META.schema){

				                        return (
				                          <script type="application/ld+json">{JSON.stringify(META.schema)}</script>
				                        )
				                      }else{
				                        return  null;
				                      }

				                    })()}
				                  </Helmet>

				                )
				              }else{
				                return null;
				              }

				            }}
				            </Data>

	            </div>
	            
            </FadeIn>

		);

	}
}

export default Layout;
